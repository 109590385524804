import React, { useEffect, useState } from "react";
import { getProfile } from "../backendApi/api";
import { useParams } from "react-router-dom";
import {
  getAddMemeTx,
  getMemeBuyPriceAfterFee,
  getMemeSellPriceAfterFee,
  getBuyMemeTx,
  getSellMemeTx,
  getMemeBalanceFromMemeId,
} from "../contracts/memeFunctions";
import { candidatesType, marketcapFromSupply, weiToEth } from "../util/util";
import { sendTx } from "../util/wallet";
import TradingViewChart from "./CandleChartComponent";
import TransactionsComponent from "./TransactionsComponent";
import { transformFirstUpperCase } from "../util/util";
import { fdvFromSupply } from "../util/util";
import { getEthPrice, ethDefaultPrice } from "../util/util";
import { useWallets } from "@privy-io/react-auth";

const BuySellComponent = ({ profile }) => {
  const { wallets } = useWallets();

  const [isByUser, setIsByUser] = useState(false);
  useEffect(() => {
    profile && profile.type === candidatesType.USER
      ? setIsByUser(true)
      : setIsByUser(false);
  }, [profile]);

  const BuySellProfile = () => {
    const [ethPrice, setEthPrice] = useState(ethDefaultPrice);
    useEffect(() => {
      getEthPrice()
        .then((res) => {
          if (res) {
            setEthPrice(Number(res));
          }
        })
        .catch((err) => {
          setEthPrice(ethDefaultPrice);
        });
    }, []);

    const { name, imgUrl } = profile;
    // flex items-center justify-center my-4
    return (
      <div className='flex items-center p-2 cursor-pointer'>
        <img
          src={imgUrl}
          alt={name}
          className='w-24 h-36 object-cover rounded-lg shadow-lg hover:shadow-xl' // Increased margin-right for more space
        />
        {/* Flex container with start alignment */}
        <div className='flex flex-col ml-8'>
          {" "}
          {/* Increased margin-left for more space */}
          <h2 className='text-lg font-semibold mb-2'>{name}</h2>{" "}
          {/* Title with bottom margin */}
          {!isByUser && (
            <p className='text-sm text-gray-400 mb-2'>@{profile.handle}</p>
          )}
          {/* <p className='text-sm text-gray-400 mb-2'>
            Marketcap:{" "}
            {marketcapFromSupply(profile.supply, profile.totalSupply, ethPrice)}
          </p> */}
          <p className='text-sm text-gray-400 mb-2'>
            Fully Diluted Marketcap:{" "}
            {fdvFromSupply(profile.supply, profile.totalSupply, ethPrice)}
          </p>
          <p className='text-sm text-gray-400 mb-2'>
            Circulating Supply: {profile.supply.toLocaleString("en-US")}
          </p>
          <p className='text-sm text-gray-400'>
            Locked Supply: {profile.totalSupply.toLocaleString("en-US")}
          </p>{" "}
          {/* Removed mb-2 from the last item if not needed */}
        </div>
      </div>
    );
  };

  const MintProfile = () => {
    const { name, imgUrl, description } = profile;

    return (
      <div className='flex flex-col items-center p-4 cursor-pointer'>
        <img
          src={imgUrl}
          alt={name}
          className='w-48 h-64 object-cover rounded-lg shadow-lg hover:shadow-xl' // Added shadow for depth
        />
        <h2 className='text-lg font-semibold mt-2'>{name}</h2>
        <p className='text-sm text-gray-400 mb-2'>
          {/* Adjusted bottom margin */}
          {description}
        </p>
        {/* Added price info */}
      </div>
    );
  };

  const { contractAddress, id } = profile;
  const [holdings, setHoldings] = useState(0);
  const [isBuy, setIsBuy] = useState(true); // State to track if 'Buy' is selected
  useEffect(() => {
    getMemeBalanceFromMemeId({ memeId: id, walletAddress: wallets[0]?.address })
      .then((res) => {
        setHoldings(res);
      })
      .catch((err) => {
        console.log("err getting holdings", err);
      });
  }, [id, isBuy, wallets[0]]);

  // const toggleBuySell = () => {
  //   setIsBuy(!isBuy);
  // };
  const onClickMint = async () => {
    // const sender = await getSender();
    // const sender = "0xa6C19aDb4Cba619a637954c739eaF708e5404055";
    if (wallets[0]) {
      const addMemeTx = await getAddMemeTx({
        memeId: id,
        sender: wallets[0].address,
      });
      await sendTx(addMemeTx, wallets[0]);
    } else {
      alert("Please connect your wallet first!");
    }
  };

  const [buyAmount, setBuyAmount] = useState(0);
  const [buyPrice, setBuyPrice] = useState(0);
  useEffect(() => {
    buyAmount === ""
      ? setBuyPrice(0)
      : getMemeBuyPriceAfterFee({ memeId: id, buyAmount })
          .then((result) => {
            // const price = Number(result);
            // const priceInEth = weiToEth(price);
            setBuyPrice(result);
          })
          .catch((err) => {
            // console.log("err: ", err);
          });
  }, [buyAmount, id]);

  const onClickBuy = async () => {
    // const sender = await getSender();
    // const sender = "0xa6C19aDb4Cba619a637954c739eaF708e5404055";
    if (wallets[0]) {
      const buyMemeTx = await getBuyMemeTx({
        memeId: id,
        amount: buyAmount,
        sender: wallets[0].address,
        price: buyPrice,
      });
      await sendTx(buyMemeTx, wallets[0]);
    } else {
      alert("Please connect your wallet first!");
    }
  };

  const [sellAmount, setSellAmount] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  useEffect(() => {
    sellAmount === 0
      ? setSellPrice(0)
      : getMemeSellPriceAfterFee({ memeId: id, sellAmount })
          .then((result) => {
            setSellPrice(result);
          })
          .catch((err) => {
            // console.log("err: ", err);
          });
  }, [id, sellAmount]);

  const onClickSell = async () => {
    // const sender = await getSender();
    if (wallets[0]) {
      const sellMemeTx = await getSellMemeTx({
        memeId: id,
        amount: sellAmount,
        sender: wallets[0].address,
        price: sellPrice,
      });
      // console.log("sellMemeTx: ", sellMemeTx);
      await sendTx(sellMemeTx, wallets[0]);
    } else {
      alert("Please connect your wallet first!");
    }
  };

  return (
    <div className='md:w-2/5 bg-gray-800 rounded-lg shadow-md p-4 my-4'>
      {contractAddress && (
        <>
          <div className='flex flex-col text-lg mb-4 font-bold'>
            <span>
              Ticker:{" "}
              {isByUser
                ? profile && transformFirstUpperCase(profile.name)
                : profile && transformFirstUpperCase(profile.handle)}
            </span>
          </div>

          <div className='flex justify-between'>
            <button
              onClick={() => setIsBuy(true)}
              className={`px-4 py-2 rounded ${
                isBuy
                  ? "bg-lime-300 hover:bg-lime-400 text-black font-semibold focus:outline-none"
                  : "bg-gray-600 px-4 py-2 rounded hover:bg-gray-500 font-semibold focus:outline-none"
              }`}>
              Buy
            </button>
            <button
              onClick={() => setIsBuy(false)}
              className={`px-4 py-2 rounded ${
                !isBuy
                  ? "bg-red-500 hover:bg-red-600 text-black font-semibold focus:outline-none"
                  : "bg-gray-600 px-4 py-2 rounded hover:bg-gray-500 font-semibold focus:outline-none"
              }`}>
              Sell
            </button>
          </div>
          <div className='my-4'>
            <div className='flex items-center justify-center my-4'>
              <button
                className='text-xl'
                onClick={() =>
                  isBuy
                    ? setBuyAmount(buyAmount - 1000000)
                    : setSellAmount(sellAmount - 1000000)
                }>
                -
              </button>
              <input
                type='number'
                className='w-2/3 mx-4 p-2 border rounded text-center text-black'
                value={isBuy ? buyAmount : sellAmount}
                onChange={(e) =>
                  isBuy
                    ? setBuyAmount(Number(e.target.value))
                    : setSellAmount(Number(e.target.value))
                }
              />
              <button
                className='text-xl'
                onClick={() =>
                  isBuy
                    ? setBuyAmount(buyAmount + 1000000)
                    : setSellAmount(sellAmount + 1000000)
                }>
                +
              </button>
            </div>
            {/* Price display */}
            <div className='flex justify-between'>
              <p className='text-sm text-left text-gray-400 mb-4'>
                {/* Adjusted bottom margin and alignment */}
                {wallets[0] && `Holding: ${holdings}`}
              </p>
              <p className='text-sm text-right text-gray-400 mb-4'>
                {/* Adjusted bottom margin and alignment */}
                {isBuy
                  ? "Price: " + weiToEth(Number(buyPrice))
                  : "Recevie: " + weiToEth(Number(sellPrice))}{" "}
                Eth
              </p>
            </div>
            {isBuy ? (
              <button
                onClick={onClickBuy}
                className='w-full bg-lime-300 text-black px-4 py-2 rounded hover:bg-lime-400 font-semibold focus:outline-none'>
                Buy
              </button>
            ) : (
              <button
                onClick={onClickSell}
                className='w-full bg-red-500 text-black px-4 py-2 rounded hover:bg-red-600 font-semibold focus:outline-none'>
                Sell
              </button>
            )}
          </div>
          <BuySellProfile />
        </>
      )}
      {contractAddress === undefined && (
        <>
          <MintProfile />
          <button
            className='w-full bg-yellow-300 text-black px-4 py-2 rounded hover:bg-yellow-500 font-semibold focus:outline-none'
            onClick={onClickMint}>
            Mint
          </button>
          <p className='text-sm text-gray-400 mt-4 mb-2'>
            After Mint confirmed in your wallet, wait a few seconds and refresh
            to start trading!
          </p>
        </>
      )}
    </div>
  );
};

export default BuySellComponent;
