import React, { useState } from "react";
import Profile from "./Profile";
import { addProfile } from "../backendApi/api";
import { candidatesType } from "../util/util";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";

const CreatePage = ({ profiles, ethPrice }) => {
  const handleCreateClick = () => {
    // Logic to handle creation (e.g., redirect to a creation form)
    setIsModalOpen(true);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000, // Ensure it's above other content
  };

  const modalStyle = {
    background: "#1a1f2e",
    padding: "20px",
    borderRadius: "5px",
    zIndex: 1001,
    width: "50%", // Increase the width of the modal
    maxWidth: "600px", // Set a maximum width if needed
    // Keep the modal in the center
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  const labelStyle = {
    display: "block",
    marginBottom: "5px",
    color: "#9ca3af",
  };

  const inputStyle = {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ddd",
    color: "black",
  };

  const textareaStyle = {
    ...inputStyle,
    height: "100px",
  };

  const closeButtonContainerStyle = {
    textAlign: "right",
  };

  const closeButtonStyle = {
    fontSize: "24px",
    lineHeight: "24px",
    width: "30px",
    height: "30px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
  };

  const modalTitleStyle = {
    textAlign: "center",
    fontSize: "24px",
    margin: "0 0 20px 0",
  };

  const buttonContainerStyle = {
    textAlign: "center",
  };

  const buttonStyle = {
    padding: "10px 20px",
    fontSize: "16px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    outline: "none",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  };

  const formGroupStyle = {
    marginTop: "15px",
    marginBottom: "15px",
  };

  const CreateModal = ({ isOpen, onClose }) => {
    const [tickerName, setTickerName] = useState("");
    const [tickerDescription, setTickerDescription] = useState("");
    const [tickerImgUrl, setTickerImgUrl] = useState("");
    const handleTickerNameChange = (event) => {
      setTickerName(event.target.value);
    };

    const handleTickerDescriptionChange = (event) => {
      setTickerDescription(event.target.value);
    };

    const handleTickerImgUrlChange = (event) => {
      setTickerImgUrl(event.target.value);
    };

    if (!isOpen) return null;
    const handleBackdropClick = (e) => {
      if (e.target === e.currentTarget) {
        onClose();
      }
    };

    const handleSubmit = async (event) => {
      event.preventDefault(); // Prevent the default form submission behavior
      const meme = {
        name: tickerName,
        description: tickerDescription,
        imgUrl: tickerImgUrl,
      };
      const result = await addProfile(meme);
      // console.log("result: ", result);

      onClose();
      navigate(`/profile/${result.id}`);
    };

    return (
      <div style={backdropStyle} onClick={handleBackdropClick}>
        <div style={modalStyle}>
          <div style={closeButtonContainerStyle}>
            <button onClick={onClose} style={closeButtonStyle}>
              &times;
            </button>
          </div>

          {/* Modal Title */}
          <h2 style={modalTitleStyle}>Start Your Meme</h2>
          <p style={{ color: "#9ca3af" }}>
            All memes are fair launch. Every meme's initial supply starts out in
            the liquidity pool (10bil tokens total). Every circulating token
            must be bought from the pool. Trading curve follows Univ2.{" "}
            <strong style={{ color: "white" }}>
              No Inititial Capital Required.
            </strong>
          </p>
          <form onSubmit={handleSubmit}>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Ticker Name:</label>
              <input
                type='text'
                name='tickerName'
                style={inputStyle}
                value={tickerName}
                onChange={handleTickerNameChange}
              />
            </div>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Ticker Description:</label>
              <textarea
                name='tickerDescription'
                style={textareaStyle}
                value={tickerDescription}
                onChange={handleTickerDescriptionChange}
              />
            </div>
            <div style={formGroupStyle}>
              <label style={labelStyle}>Ticker Image URL:</label>
              <input
                type='text'
                name='tickerImgUrl'
                style={inputStyle}
                value={tickerImgUrl}
                onChange={handleTickerImgUrlChange}
              />
            </div>
            <div style={formGroupStyle}>
              <div style={buttonContainerStyle}>
                <button
                  type='submit'
                  className='bg-yellow-300 hover:bg-yellow-500 font-bold text-black'
                  style={buttonStyle}
                  disabled={
                    tickerName === "" ||
                    tickerDescription === "" ||
                    tickerImgUrl === ""
                  }>
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className='container mx-auto px-4'>
      {/* Create Button */}
      <div className='mb-4 mt-4 text-center'>
        <button
          className='bg-yellow-300 hover:bg-yellow-500 text-black font-bold py-4 px-8 rounded'
          onClick={handleCreateClick}>
          Start Your 1000x Meme With For Free!
        </button>
      </div>
      <h2 className='text-2xl font-bold mb-4 ml-18'>Top Memes</h2>
      <div className='mb-8'>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
          {profiles.length > 0 &&
            profiles.map(
              (profile, index) =>
                profile.type === candidatesType.USER && (
                  <Profile key={index} profile={profile} ethPrice={ethPrice} />
                )
            )}
        </div>
      </div>
      <CreateModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </div>
  );
};

export default CreatePage;
