import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PrivyProvider } from "@privy-io/react-auth";
// Replace this with any of the networks listed at https://viem.sh/docs/clients/chains.html
import { base, goerli, mainnet } from "viem/chains";

const privyConfig = {
  loginMethods: ["wallet"],
  defaultChain: goerli,
  supportedChains: [goerli, mainnet],
  appearance: {
    theme: "#1a1f2e",
    accentColor: "#FCFC03",
    showWalletLoginFirst: true,
    logo: "favicon.ico",
  },
  // legal: {
  //   termsAndConditionsUrl: "https://your-terms-and-conditions-url",
  //   privacyPolicyUrl: "https://your-privacy-policy-url",
  // },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <PrivyProvider
      appId={process.env.REACT_APP_PRIVY_APP_ID}
      config={privyConfig}>
      <App />
    </PrivyProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
