import React, { useEffect, useState } from "react";
import { getProfile } from "../backendApi/api";
import { useParams } from "react-router-dom";
import { weiToEth, formatTimestamp } from "../util/util";
import { sendTx } from "../util/wallet";
import TradingViewChart from "./CandleChartComponent";
import TransactionsComponent from "./TransactionsComponent";
import BuySellComponent from "./BuySellComponent";
import { getTransactionsByMeme } from "../backendApi/api";

const BuySellPage = () => {
  const { handle } = useParams();
  const [profile, setProfile] = useState({});
  useEffect(() => {
    getProfile(handle)
      .then((res) => {
        setProfile(res);
      })
      .catch((err) => {
        console.log("err fetching profile", err);
      });
  }, [handle]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    if (profile) {
      const memeId = profile.id;
      getTransactionsByMeme(memeId)
        .then((res) => {
          res.forEach((tx) => {
            tx.timestamp = formatTimestamp(tx.timestamp);
          });
          setTransactions(res);
        })
        .catch((err) => {
          console.log("err fetching transactions", err);
        });
    }
  }, [profile]);

  return profile.contractAddress !== undefined ? (
    <div className="container mx-auto px-4">
      <div className="mx-auto flex flex-col md:flex-row">
        {!isMobile && (
          <div className="md:w-3/5 p-4">
            <TradingViewChart profile={profile} transactions={transactions} />
          </div>
        )}
        <BuySellComponent profile={profile} />
        {isMobile && (
          <div className="p-4">
            <TradingViewChart profile={profile} transactions={transactions} />
          </div>
        )}
      </div>
      <TransactionsComponent
        profile={profile}
        transactions={transactions}
        isMobile={isMobile}
      />
    </div>
  ) : (
    <div className="container mx-auto px-4 flex items-center justify-center">
      <BuySellComponent profile={profile} />
    </div>
  );
};

export default BuySellPage;
