// const baseUrl = "http://localhost:5000/predicitonmarket/us-central1/api";
const baseUrl = "https://api-iyxgmmkr2q-uc.a.run.app";

const FeedUrl = `${baseUrl}/posts`;
const ProfilesUrl = `${baseUrl}/candidates`;
const ProfilesByUserUrl = `${baseUrl}/candidates/user`;
const ProfileUrl = `${baseUrl}/candidate`;
const MyMemesUrl = `${baseUrl}/holdings/user`;
const TransactionsByMemeUrl = `${baseUrl}/transactions`;

export {
  FeedUrl,
  ProfilesUrl,
  ProfileUrl,
  MyMemesUrl,
  TransactionsByMemeUrl,
  ProfilesByUserUrl,
};
