// Tab.js
import React from "react";

const Tab = ({ label, isActive, onClick }) => {
  return (
    <button
      className={`px-5 py-3 font-semibold text-lg ${
        isActive ? "text-yellow-300" : "text-white"
      } hover:text-yellow-500`}
      onClick={onClick}>
      {label}
    </button>
  );
};

export default Tab;
