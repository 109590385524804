// Feed.js
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import Post from "./Post";
import { getFeed } from "../backendApi/api";
import BurnedMemesModal from "./BurnedMemeComponent";

const Feed = () => {
  const [posts, setPosts] = useState([]);
  useEffect(() => {
    getFeed()
      .then((posts) => {
        console.log("posts: ", posts);
        setPosts(posts);
      })
      .catch((err) => {
        console.log("error fetching posts: ", err);
      });
  }, []);

  return (
    <div className='feed-container mx-auto my-4 max-w-md md:max-w-lg lg:max-w-2xl'>
      <h2 className='text-2xl font-bold mb-4 ml-18'>Posts</h2>
      {posts.length > 0 &&
        posts.map((post, index) => (
          <Post key={index} index={index} post={post} />
        ))}
    </div>
  );
};

export default Feed;
