import React, { useEffect, useState } from "react";
import { chainId } from "../contracts/memeFunctions";

const sendTx = async (tx, wallet) => {
  try {
    tx.chainId = chainId;
    const provider = await wallet?.getEthersProvider();
    const signer = await provider?.getSigner();
    signer.sendTransaction(tx).then((completedTx) => {
      console.log(completedTx);
    });
  } catch (error) {
    console.error("Error sending tx:", error);
  }
  // // Check if MetaMask is installed
  // if (typeof window.ethereum !== "undefined") {
  //   try {
  //     // Send the transaction
  //     // console.log("tx: ", tx);
  //     tx.gasPrice = `0x${tx.gasPrice.toString(16)}`;
  //     if (tx.value) {
  //       tx.value = `0x${tx.value.toString(16)}`;
  //     }
  //     const txHash = await window.ethereum.request({
  //       method: "eth_sendTransaction",
  //       params: [tx],
  //     });

  //     console.log("Transaction Hash: ", txHash);
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // } else {
  //   console.log("Please install MetaMask!");
  // }
};

const ConnectWalletButton = ({ sender, setSender, connectWallet }) => {
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    setIsConnected(sender !== null);
  }, [sender]);

  useEffect(() => {
    const checkConnection = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({
            method: "eth_accounts", // Changed from eth_requestAccounts to eth_accounts
          });
          if (accounts.length > 0) {
            setSender(accounts[0]); // Set the first account
          }
        } catch (error) {
          console.error(error);
          // Set to null in case of an error
        }
      } else {
        // alert("Please install MetaMask!");
        // Set to null if MetaMask is not installed
      }
    };

    checkConnection();
  }, [setSender]);
  return (
    <button
      onClick={connectWallet}
      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
      disabled={isConnected}>
      {isConnected ? "Connected" : "Connect Wallet"}
    </button>
  );
};

export { sendTx, ConnectWalletButton };
