import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getMemeBuyPriceAfterFee } from "../contracts/memeFunctions";
import { weiToEth, priceFromSupply, initialTokens } from "../util/util";

const Profile = ({ profile, ethPrice }) => {
  const { id, imgUrl, handle, name, description } = profile;

  const navigate = useNavigate();
  const onClickProfile = () => {
    navigate(`/profile/${handle}`);
  };

  // const [price, setPrice] = useState(0);
  // useEffect(() => {
  //   getMemeBuyPriceAfterFee({ memeId: id, buyAmount: 1 })
  //     .then((res) => {
  //       console.log("Res: ", res);
  //       setPrice(res);
  //     })
  //     .catch((err) => console.log("err getting price: ", err));
  // }, []);

  return (
    <div
      className='flex flex-col items-center p-4 cursor-pointer hover:bg-gray-700 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105 rounded-lg'
      onClick={onClickProfile}>
      <img
        src={imgUrl}
        alt={name}
        className='w-48 h-64 object-cover rounded-lg shadow-lg hover:shadow-xl' // Added shadow for depth
      />
      <h2 className='text-lg font-semibold mt-2'>{name}</h2>
      <p className='text-sm text-gray-400'>{description}</p>
      <p className='text-sm text-yellow-300'>
        {/* Price: {weiToEth(Number(price)).toFixed(10)}eth */}
        {profile.contractAddress !== undefined
          ? profile.supply === 0
            ? "Price: " + 0
            : "Price: " +
              priceFromSupply(profile.supply, profile.totalSupply, ethPrice)
          : "Not Minted"}
      </p>
      {/* Added price info */}
    </div>
  );
};

export default Profile;
