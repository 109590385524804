const { web3, contract, chainId, contractAddress } = require("./contracts");
const nullAddress = "0x0000000000000000000000000000000000000000";

const getMemeSupplyFromMemeId = async (memeId) => {
  // 8FKK7NNt4AXWMLGw2sOk
  return contract.methods
    .memes(memeId)
    .call()
    .then(async (memeAddress) => {
      // console.log("Address associated with meme ID: ", memeAddress);
      // may need to proces buy price
      const supply = await getMemeSupplyFromMemeAddress(memeAddress);
      return supply;
    })
    .catch((error) => {
      console.error("Error fetching address: ", error);
      return error;
    });
};

const getMemeSupplyFromMemeAddress = async (address) => {
  if (address === nullAddress) {
    return 0;
  }
  return contract.methods
    .memesSupply(address)
    .call()
    .then((result) => {
      // console.log("Address associated with meme ID: ", result);
      return result;
    })
    .catch((error) => {
      console.error("Error fetching address: ", error);
    });
};

const getMemeBalanceFromMemeId = async ({ memeId, walletAddress }) => {
  // !!!should change this into one call, add function to contract!!!
  // console.log("walletAddress: ", walletAddress);
  return contract.methods
    .memes(memeId)
    .call()
    .then(async (memeAddress) => {
      console.log("memeId: ", memeId);
      // console.log("Address associated with meme ID: ", memeAddress);
      const balance = await getMemeBalanceFromMemeAddress(
        memeAddress,
        walletAddress
      );
      return balance;
    })
    .catch((error) => {
      console.error("Error getMemeBalanceFromMemeId: ", error);
    });
};

const getMemeBalanceFromMemeAddress = async (address, walletAddress) => {
  if (!walletAddress || address === nullAddress) {
    return 0;
  }

  return contract.methods
    .memesBalance(address, walletAddress)
    .call()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.error("Error getMemeBalanceFromMemeAddress: ", error);
    });
};

const getMemeBuyPriceAfterFee = async ({ memeId, buyAmount }) => {
  return contract.methods
    .getBuyPriceAfterFee(memeId, buyAmount)
    .call()
    .then((result) => {
      // console.log("Address associated with meme ID: ", result);
      // may need to proces buy price
      return result;
    })
    .catch((error) => {
      // console.error("Error getting buy price: ", error);
    });
};

const getMemeSellPriceAfterFee = async ({ memeId, sellAmount }) => {
  return contract.methods
    .getSellPriceAfterFee(memeId, sellAmount)
    .call()
    .then((result) => {
      // console.log("Address associated with meme ID: ", result);
      // may need to proces buy price
      return result;
    })
    .catch((error) => {
      // console.error("Error getting sell price: ", error);
    });
};

const getMemeLpFeesForUser = async ({ memeId, sender }) => {
  // console.log("memeid: ", memeId);
  // console.log("sender: ", sender);

  return contract.methods
    .getLiquidityFees(memeId, sender)
    .call()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // console.error("Error getting sell price: ", error);
    });
};

const getCurrentGasPrice = async () => {
  const crGasPrice = await web3.eth.getGasPrice();
  return crGasPrice;
};

const getCurrentNonce = async (accountAddress) => {
  const currentNonce = await web3.eth.getTransactionCount(
    accountAddress,
    "latest"
  );
  // console.log("currentNonce: ", currentNonce);
  return currentNonce;
};

const getClaimMemeFeeTx = async ({ memeId, sender }) => {
  const claimFeeTx = (memeId, nonce, gasPrice, sender) => {
    // console.log("pk: ", pk);
    const data = contract.methods.claimLiquidityFees(memeId);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
    };
    return unsignedTx;
  };
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  // console.log("currentNonce: ", currentNonce);
  return claimFeeTx(memeId, currentNonce, currentGasPrice, sender);
};

const getAddMemeTx = async ({ memeId, sender }) => {
  // need to fix price!!!
  const addMemeTx = (memeId, nonce, gasPrice, sender) => {
    const data = contract.methods.addMeme(memeId);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
    };
    return unsignedTx;
  };
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  return addMemeTx(memeId, currentNonce, currentGasPrice, sender);
};

const getBuyMemeTx = async ({ memeId, amount, sender, price }) => {
  // need to fix price!!!
  const buyMemeTx = (memeId, amount, nonce, gasPrice, sender, price) => {
    const data = contract.methods.buyMemes(memeId, amount);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
      value: price,
    };
    return unsignedTx;
  };
  // console.log("sender: ", sender);
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  // console.log("currentNonce: ", currentNonce);
  console.log();
  return buyMemeTx(
    memeId,
    amount,
    currentNonce,
    currentGasPrice,
    sender,
    price
  );
};

const getSellMemeTx = async ({ memeId, amount, sender }) => {
  const sellMemeTx = (memeId, amount, nonce, gasPrice, sender) => {
    // console.log("pk: ", pk);
    const data = contract.methods.sellMemes(memeId, amount);
    const unsignedTx = {
      to: contractAddress,
      data: data.encodeABI(),
      gasPrice: gasPrice, // Estimate or set the gas limit
      from: sender,
      nonce: web3.utils.toHex(nonce),
    };
    return unsignedTx;
  };
  const currentNonce = await getCurrentNonce(sender);
  const currentGasPrice = await getCurrentGasPrice();
  // console.log("currentNonce: ", currentNonce);
  return sellMemeTx(memeId, amount, currentNonce, currentGasPrice, sender);
};

export {
  getMemeSupplyFromMemeId,
  getMemeBalanceFromMemeId,
  getMemeBuyPriceAfterFee,
  getMemeSellPriceAfterFee,
  getAddMemeTx,
  getBuyMemeTx,
  getSellMemeTx,
  getMemeLpFeesForUser,
  getClaimMemeFeeTx,
  chainId,
};

// const main = async () => {
//   // const memeId = "1";
//   // const sender = "0xa6c19adb4cba619a637954c739eaf708e5404055";
//   // const tx = await getAddMemeTx({ memeId, sender });
//   // console.log("Tx: ", tx);

//   const memeId = "4gSM7p7PFo47oxBjQuFO";
//   const result = await getMemeBuyPriceAfterFee({ memeId, buyAmount: 100 });
//   console.log("result: ", result);
// };

// main();
