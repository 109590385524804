import React from "react";

const BurnedMemesModal = ({
  isOpen,
  onClose,
  onClickViewBurnedMemes,
  post,
}) => {
  if (!isOpen) return null;

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle = {
    background: "#1a1f2e",
    padding: "20px",
    borderRadius: "5px",
    zIndex: 1001,
    width: "50%",
    maxWidth: "600px",
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  const closeButtonStyle = {
    fontSize: "24px",
    lineHeight: "24px",
    width: "30px",
    height: "30px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    color: "white",
    position: "absolute",
    top: "10px",
    right: "10px",
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={backdropStyle} onClick={handleBackdropClick}>
      <div style={modalStyle}>
        <button onClick={onClose} style={closeButtonStyle}>
          &times;
        </button>

        <h2
          style={{
            textAlign: "center",
            fontSize: "24px",
            margin: "0 0 20px 0",
            color: "white",
          }}>
          Burned Memes
        </h2>

        <p style={{ color: "#9ca3af" }}>
          Every tweet by person/company on the Home page will trigger protocol
          to buy back and burn. This tweet has triggered the protocol to use{" "}
          {post.burnedEthAmount} Eth to buy back and burn{" "}
          {post.burnedMemeAmount} {post.handle} memes.
          <div
            className='underline cursor-pointer hover:text-yellow-500'
            onClick={onClickViewBurnedMemes}>
            View Burn Here
          </div>
        </p>
      </div>
    </div>
  );
};

export default BurnedMemesModal;
