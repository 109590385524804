// Tabs.js
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
import Tab from "./Tab";

const Tabs = ({ activeTab, setActiveTab }) => {
  const tabs = [
    { key: "feed", label: "Feed" },
    { key: "home", label: "Home" },
    { key: "create", label: "Create" },
    { key: "claim", label: "Claim" },
  ];

  const navigate = useNavigate();
  const onClickTab = (key) => {
    setActiveTab(key);
    navigate(`/${key}`);
  };

  return (
    <div className='flex justify-center pt-4'>
      {tabs.map((tab) => (
        <Tab
          key={tab.key}
          label={tab.label}
          isActive={tab.key === activeTab}
          onClick={() => onClickTab(tab.key)}
        />
      ))}
    </div>
  );
};

export default Tabs;
