import React from "react";
import { initialEthBalance } from "../util/util";

const FaqModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle = {
    background: "#1a1f2e",
    padding: "20px",
    borderRadius: "5px",
    zIndex: 1001,
    width: "50%",
    maxWidth: "600px",
    position: "fixed",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  };

  const closeButtonContainerStyle = {
    textAlign: "right",
  };

  const closeButtonStyle = {
    fontSize: "24px",
    lineHeight: "24px",
    width: "30px",
    height: "30px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
    color: "white",
  };

  const modalTitleStyle = {
    textAlign: "center",
    fontSize: "24px",
    margin: "0 0 20px 0",
    color: "white",
  };

  const listStyle = {
    listStyleType: "decimal",
    marginLeft: "20px",
    color: "#9ca3af",
    marginBottom: "20px",
  };

  const listItemStyle = {
    marginBottom: "10px",
  };

  const strongStyle = {
    color: "white",
  };

  const handleBackdropClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={backdropStyle} onClick={handleBackdropClick}>
      <div style={modalStyle}>
        <div style={closeButtonContainerStyle}>
          <button onClick={onClose} style={closeButtonStyle}>
            &times;
          </button>
        </div>

        <h2 style={modalTitleStyle}>Bet & Burn Memes</h2>

        <ul style={listStyle}>
          <li style={listItemStyle}>
            All memes are fair launch. Every meme's initial supply starts out in
            the liquidity pool (10bil tokens total). Every circulating token
            must be bought from the pool. Trading curve follows Univ2.{" "}
          </li>
          <li style={listItemStyle}>
            There is <strong style={strongStyle}>0 rugs</strong>, as the
            liquidity pool cannot be removed.
          </li>
          <li style={listItemStyle}>
            You can always instantly buy & sell any meme. There is 0.95% trading
            fee, 0.25% to the meme holders, 0.5% to the bounty, and 0.2% to the
            protocol.{" "}
            <strong style={strongStyle}>
              All meme holders automatically receive fees (proportionally).
            </strong>
          </li>
          <li style={listItemStyle}>
            Bounty is for buying & burning memes.{" "}
            <strong style={strongStyle}>
              Every time there is a tweet (excluding retweets) by the
              company/person on the Home page, 5% of the bounty pool is used to
              buy and burn the corresponding meme.
            </strong>{" "}
            Every buy & burn will push up the meme's price and remove a portion
            of its total supply permanently. Each burn hash can be viewed in
            Feed.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default FaqModal;
