// ProfilesList.js
import React, { useState, useEffect } from "react";
import Profile from "./Profile";
import { getProfiles } from "../backendApi/api";
import { getEthPrice, ethDefaultPrice } from "../util/util";
import { candidatesType } from "../util/util";
import FaqModal from "./FAQComponent";

const BountyComponent = () => {
  const [bounty, setBounty] = useState(11.269);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const handleOpenFaq = () => {
    setIsFaqOpen(true);
  };

  const handleCloseFaq = () => {
    setIsFaqOpen(false);
  };

  return (
    <div className='text-white p-4 rounded-lg mb-4 flex justify-center'>
      <div className='flex items-center'>
        {/* You can replace the image source with your GIF */}
        <img
          src='https://media2.giphy.com/media/l2Je00TmqeLx0gavS/giphy.gif'
          className='mr-5'
          width='100'
          height='100'
          alt='bounty'
        />
        <div className='text-center'>
          <h3 className='font-semibold text-xl'>Bounty</h3>
          <p className='text-lg'>{bounty} ETH</p>
          <p
            className='text-lg text-white underline hover:text-yellow-500 cursor-pointer'
            onClick={handleOpenFaq}>
            How it Works
          </p>
        </div>
      </div>
      <FaqModal isOpen={isFaqOpen} onClose={handleCloseFaq} />
    </div>
  );
};

const ProfilesList = ({ profiles, ethPrice }) => {
  const [sepProfiles, setSepProfiles] = useState(null);
  useEffect(() => {
    const separatedProfiles = {};
    if (profiles && profiles.length > 0) {
      profiles.forEach((profile) => {
        // If the type key doesn't exist in separatedProfiles, create it
        if (!separatedProfiles[profile.type]) {
          separatedProfiles[profile.type] = [];
        }
        // Add the current profile to the appropriate type array
        separatedProfiles[profile.type].push(profile);
      });
      setSepProfiles(separatedProfiles);
    }
  }, [profiles]);

  const ProfileComponent = ({ type, profiles }) => {
    return (
      <div className='mb-8'>
        {/* Adjusted title with larger font and added left margin */}
        <h2 className='text-2xl font-bold mb-4 ml-18'>{type}</h2>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
          {profiles &&
            profiles.length > 0 &&
            profiles.map((profile, index) => (
              <Profile key={index} profile={profile} ethPrice={ethPrice} />
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className='container mx-auto px-4'>
      <BountyComponent />
      {/* {sepProfiles && (
        <ProfileComponent
          type={"Elon"}
          seperatedProfiles={sepProfiles["Elon"]}
        />
      )} */}

      {sepProfiles &&
        Object.entries(sepProfiles).map(
          ([type, profiles]) =>
            type !== candidatesType.USER && (
              <ProfileComponent key={type} type={type} profiles={profiles} />
            )
        )}

      {/* <div className='mb-8'>
        <h2 className='text-2xl font-bold mb-4 ml-18'>Elon</h2>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
          {profiles.map((profile) => (
            <Profile profile={profile} ethPrice={ethPrice} />
          ))}
        </div>
      </div>

      <div className='mb-8'>
        <h2 className='text-2xl font-bold mb-4 ml-18'>Elections</h2>
        <div className='grid grid-cols-1 md:grid-cols-4 gap-4'>
          {profiles.map((profile) => (
            <Profile profile={profile} />
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ProfilesList;
