const timeSince = (unixTimestamp) => {
  const pastDate = new Date(unixTimestamp * 1000); // Convert to milliseconds
  const now = new Date();
  const timeDiff = now - pastDate;

  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (minutes < 60) {
    return `${minutes}m`;
  } else if (hours < 24) {
    return `${hours}h`;
  } else if (days < 7) {
    return `${days} day${days > 1 ? "s" : ""}`;
  } else if (weeks < 4) {
    return `${weeks} week${weeks > 1 ? "s" : ""}`;
  } else if (months < 12) {
    return `${months} month${months > 1 ? "s" : ""}`;
  } else {
    return `${years} year${years > 1 ? "s" : ""}`;
  }
};

const weiToEth = (gwei) => {
  return gwei / 1e18;
};

// function formatTimestamp(timestamp) {
//   const date = new Date(timestamp._seconds * 1000);
//   const options = {
//     year: "numeric",
//     month: "numeric",
//     day: "numeric",
//     hour12: false,
//   };

//   // Combine the date and time strings
//   return (
//     date.toLocaleDateString("en-US", options) +
//     " " +
//     date.toLocaleTimeString("en-US", { hour12: false })
//   );
// }

function formatTimestamp(timestamp) {
  const date = new Date(timestamp._seconds * 1000);

  // Formatting each part of the date-time to ensure leading zeros
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Combine the date and time parts in the desired format
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

function transformFirstUpperCase(name) {
  if (name) {
    return name.charAt(0).toUpperCase() + name.substring(1);
  } else {
    return "";
  }
}

function truncateAddress(address) {
  if (address) {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  } else {
    return "";
  }
}

const eventType = {
  MINT: "mint",
  TRADE: "trade",
  Burn: "burn",
};

const candidatesType = {
  ELON: "Elon",
  ELECTION: "Election",
  USER: "User",
};

const initialTokens = 10000000000;
const initialEthBalance = 69.42;
const initialEther = initialEthBalance * Math.pow(10, 18);

const priceFromSupply = (supply, totalSupply, ethPrice) => {
  // if there is burn
  const tokenBalance = totalSupply - supply;
  const ethBalance = (initialEthBalance * initialTokens) / tokenBalance;

  const price = (ethBalance * tokenBalance) / (tokenBalance - 1) - ethBalance;

  return (price * ethPrice).toFixed(15).replace(/\.?0+$/, "");
};

const ethPriceFromSupply = (supply, totalSupply) => {
  // if there is burn
  const tokenBalance = totalSupply - supply;
  const ethBalance = (initialEthBalance * initialTokens) / tokenBalance;

  const price = (ethBalance * tokenBalance) / (tokenBalance - 1) - ethBalance;

  return price;
};

const marketcapFromSupply = (supply, totalSupply, ethPrice) => {
  // console.log(
  //   "price: ",
  //   Number(priceFromSupply(supply, totalSupply, ethPrice))
  // );
  return (
    Number(priceFromSupply(supply, totalSupply, ethPrice)) * Number(supply)
  )
    .toFixed(3)
    .replace(/\.?0+$/, "");
};

const fdvFromSupply = (supply, totalSupply, ethPrice) => {
  return (
    Number(priceFromSupply(supply, totalSupply, ethPrice)) * Number(totalSupply)
  )
    .toFixed(3)
    .replace(/\.?0+$/, "");
};

const ethDefaultPrice = 2300;
async function getEthPrice() {
  // const url =
  //   "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd";

  // try {
  //   const response = await fetch(url);
  //   const data = await response.json();
  //   const price = data.ethereum.usd;
  //   return price;
  // } catch (error) {
  //   console.error("Error fetching the ETH price:", error);
  // }
  return ethDefaultPrice;
}

export {
  timeSince,
  weiToEth,
  formatTimestamp,
  transformFirstUpperCase,
  truncateAddress,
  priceFromSupply,
  getEthPrice,
  ethDefaultPrice,
  initialTokens,
  initialEthBalance,
  eventType,
  candidatesType,
  fdvFromSupply,
  marketcapFromSupply,
  ethPriceFromSupply,
};
