import React, { useEffect, useState } from "react";
import { getProfile } from "../backendApi/api";
import { useParams } from "react-router-dom";
import { candidatesType, weiToEth } from "../util/util";
import { sendTx } from "../util/wallet";
import { getTransactionsByMeme } from "../backendApi/api";
import { transformFirstUpperCase, truncateAddress } from "../util/util";
import { eventType } from "../util/util";

const TransactionsComponent = ({ profile, transactions, isMobile }) => {
  // Sample transaction data
  //   const transactions = [
  //     {
  //       account: "0xtamagoyaki",
  //       direction: "Buy",
  //       sol: "0.00001484",
  //       sorana: "100",
  //       timestamp: "1/22/2024, 10:15:24 AM",
  //       transaction: "jDuYb4",
  //     },
  //   ];

  const [isByUser, setIsByUser] = useState(false);
  useEffect(() => {
    profile && profile.type === candidatesType.USER
      ? setIsByUser(true)
      : setIsByUser(false);
  }, [profile]);

  const [formattedTransactions, setFormattedTransactions] =
    useState(transactions);
  useEffect(() => {
    const updatedTransactions = [];
    transactions.forEach((tx) => {
      let type;
      if (tx.type === eventType.TRADE) {
        type = tx.isBuy ? "Buy" : "Sell";
      } else {
        type = transformFirstUpperCase(tx.type);
      }
      updatedTransactions.push({
        account: truncateAddress(tx.trader),
        type: type,
        eth: tx.ethAmount === 0 ? 0 : tx.ethAmount.toFixed(9),
        ticker: tx.memeAmount,
        timestamp: tx.timestamp,
        transaction: truncateAddress(tx.hash),
      });
    });
    setFormattedTransactions(updatedTransactions);
  }, [profile.handle, transactions]);

  const getTypeColor = (type) => {
    if (type === "Buy" || type === transformFirstUpperCase(eventType.MINT)) {
      return "text-green-500";
    } else if (type === "Sell") {
      return "text-red-500";
    } else if (type === transformFirstUpperCase(eventType.Burn)) {
      return "text-orange-500";
    }
  };

  return (
    <div className='shadow-md rounded-lg overflow-hidden'>
      <div className='bg-gray-800 text-white p-4'>
        <div className='grid grid-cols-6 text-xs md:text-sm'>
          <div>Address</div>
          <div>Type</div>
          <div>Amount Eth</div>
          <div>
            Amount{" "}
            {isByUser
              ? profile && transformFirstUpperCase(profile.name)
              : profile && transformFirstUpperCase(profile.handle)}
          </div>
          <div>Timestamp</div>
          <div>Transaction</div>
        </div>
      </div>
      <div>
        {formattedTransactions.length > 0 &&
          formattedTransactions.map((tx, index) => (
            <div
              key={index}
              className={`grid grid-cols-6 text-xs md:text-sm p-4 text-gray-400 ${
                index % 2 === 0 ? "bg-gray-700" : "bg-gray-600"
              }`}>
              <div className='text-blue-500'>{tx.account}</div>
              <div className={getTypeColor(tx.type)}>{tx.type}</div>
              <div>{tx.eth}</div>
              <div>{tx.ticker}</div>
              <div>{tx.timestamp}</div>
              <div className='truncate'>{tx.transaction}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TransactionsComponent;
