import React, { useRef, useEffect, useState } from "react";
import { createChart } from "lightweight-charts";
import { candidatesType, formatTimestamp } from "../util/util";
import { getEthPrice, ethDefaultPrice } from "../util/util";
import { transformFirstUpperCase, ethPriceFromSupply } from "../util/util";
import { initialTokens, initialEthBalance } from "../util/util";

const TradingViewChart = ({ profile, transactions }) => {
  const [isByUser, setIsByUser] = useState(false);
  useEffect(() => {
    profile && profile.type === candidatesType.USER
      ? setIsByUser(true)
      : setIsByUser(false);
  }, [profile]);

  const [ethPrice, setEthPrice] = useState(ethDefaultPrice);
  useEffect(() => {
    getEthPrice()
      .then((res) => {
        if (res) {
          setEthPrice(Number(res));
        }
      })
      .catch((err) => {
        setEthPrice(ethDefaultPrice);
      });
  }, []);

  function groupByHour(transactions) {
    let groups = {};
    transactions.forEach((t) => {
      let date = new Date(t.timestamp);
      // const unixTimestamp = Math.floor(date.getTime() / 1000);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);
      const unixTimestamp = Math.floor(date.getTime() / 1000);

      // let hour = `${date.getFullYear()}-${
      //   date.getMonth() + 1
      // }-${date.getDate()} ${date.getHours()}:00:00`;
      if (!groups[unixTimestamp]) {
        groups[unixTimestamp] = [];
      }
      groups[unixTimestamp].push(t);
    });
    return groups;
  }

  // Example usage
  const [candleData, setCandleData] = useState([]);
  // let candleData = createCandleData(transactions);
  useEffect(() => {
    function createCandleData(transactions) {
      // Sort transactions by timestamp
      let sortedTransactions = [...transactions];
      sortedTransactions
        // .filter((transaction) => transaction.type !== "mint")
        .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

      // Group transactions by hour
      let groupedTransactions = groupByHour(sortedTransactions);

      // Calculate OHLC for each group
      let candles = Object.keys(groupedTransactions).map((hour) => {
        let transactionsInHour = groupedTransactions[hour];
        let prices = transactionsInHour.map(
          (t) =>
            t.supply === 0
              ? (initialEthBalance * initialTokens) / (initialTokens - 1) -
                initialEthBalance
              : ethPriceFromSupply(t.supply, t.supply + t.lockedSupply)
          // : t.ethAmount / t.memeAmount
        );

        let open = prices[0] * ethPrice;
        let close = prices[prices.length - 1] * ethPrice;
        let high = Math.max(...prices) * ethPrice;
        let low = Math.min(...prices) * ethPrice;

        // let open = prices[0];
        // let close = prices[prices.length - 1];
        // let high = Math.max(...prices);
        // let low = Math.min(...prices);

        // console.log("Res: ", {
        //   time: Number(hour),
        //   open,
        //   high,
        //   low,
        //   close,
        // });

        return { time: Number(hour), open, high, low, close };
      });
      return candles;
    }

    function adjustCandleOpens(candles) {
      for (let i = 1; i < candles.length; i++) {
        candles[i].open = candles[i - 1].close;
      }
      return candles;
    }

    if (transactions.length > 0) {
      const candles = createCandleData(transactions);
      const adjustedCandles = adjustCandleOpens(candles);
      setCandleData(adjustedCandles);
    }
  }, [transactions, ethPrice]);

  const chartContainerRef = useRef(null);

  useEffect(() => {
    if (chartContainerRef.current) {
      const chart = createChart(chartContainerRef.current, {
        width: chartContainerRef.current.clientWidth,
        height: 400,
        layout: {
          background: { color: "#1a1f2e" },
          textColor: "#9ca3af",
        },
        grid: {
          vertLines: {
            color: "transparent",
          },
          horzLines: {
            color: "transparent",
          },
        },
        timeScale: {
          timeVisible: true, // Make the time visible
          secondsVisible: false, // Do not show seconds
          // fixLeftEdge: true, // Fix the left edge to prevent the chart from scrolling to the left
          // fixRightEdge: true, // Fix the right edge to prevent the chart from scrolling to the right
          rightOffset: 5, // No offset from the right
          // barSpacing: 1, // The spacing between bars, can be adjusted to your preference
          // minBarSpacing: 5, // Minimum spacing between bars
          // lockVisibleTimeRangeOnResize: true, // Lock time range when resizing
        },
      });

      // const hourlyData = [
      //   { time: 1609502400, open: 100, high: 105, low: 95, close: 102 }, // 2021-01-01 09:00 UTC
      //   { time: 1609506000, open: 102, high: 110, low: 101, close: 107 }, // 2021-01-01 10:00 UTC
      //   { time: 1609509600, open: 107, high: 115, low: 106, close: 112 }, // 2021-01-01 11:00 UTC
      //   // ... more data points for each hour
      // ];

      const candleSeries = chart.addCandlestickSeries({
        upColor: "rgba(38, 166, 154, 1)",
        downColor: "rgba(255, 82, 82, 1)",
        borderDownColor: "rgba(255, 82, 82, 1)",
        borderUpColor: "rgba(38, 166, 154, 1)",
        wickDownColor: "rgba(255, 82, 82, 1)",
        wickUpColor: "rgba(38, 166, 154, 1)",
        priceFormat: {
          type: "price",
          precision: 10, // Set precision to 6 decimal places
          minMove: 0.0000000001,
        },
      });

      // const fakeData = [
      //   {
      //     time: "2021-01-01",
      //     open: 100,
      //     high: 110,
      //     low: 90,
      //     close: 105,
      //   },
      //   { time: "2021-01-02", open: 105, high: 120, low: 104, close: 115 },
      //   { time: "2021-01-03", open: 115, high: 117, low: 95, close: 96 },
      //   { time: "2021-01-04", open: 96, high: 105, low: 85, close: 100 },
      //   { time: "2021-01-05", open: 100, high: 110, low: 95, close: 105 },
      //   { time: "2021-01-06", open: 106, high: 120, low: 101, close: 110 },
      //   { time: "2021-01-07", open: 110, high: 115, low: 90, close: 94 },
      //   { time: "2021-01-08", open: 94, high: 102, low: 90, close: 99 },
      //   { time: "2021-01-09", open: 99, high: 111, low: 98, close: 108 },
      //   { time: "2021-01-10", open: 108, high: 120, low: 107, close: 118 },
      //   { time: "2021-01-11", open: 118, high: 121, low: 115, close: 120 },
      //   { time: "2021-01-12", open: 120, high: 130, low: 119, close: 125 },
      //   { time: "2021-01-13", open: 125, high: 136, low: 124, close: 134 },
      //   { time: "2021-01-14", open: 134, high: 140, low: 132, close: 138 },
      //   { time: "2021-01-15", open: 138, high: 145, low: 137, close: 142 },
      //   { time: "2021-01-16", open: 142, high: 150, low: 140, close: 147 },
      //   { time: "2021-01-17", open: 147, high: 155, low: 146, close: 150 },
      //   { time: "2021-01-18", open: 150, high: 160, low: 149, close: 158 },
      //   { time: "2021-01-19", open: 158, high: 165, low: 157, close: 162 },
      //   { time: "2021-01-20", open: 162, high: 170, low: 160, close: 168 },
      // ];

      candleSeries.setData(candleData);

      const zoomOnLastPrice = () => {
        const lastDataPoint = candleData[candleData.length - 1] || {};
        const lastPrice = lastDataPoint.close;
        const zoomFactor = 0.5; // Determines the zoom level (10% in this case)

        const highestPrice = Math.max(...candleData.map((d) => d.high));
        const lowestPrice = Math.min(...candleData.map((d) => d.low));

        // Calculate margins
        const topMargin =
          (highestPrice - lastPrice) / (highestPrice - lowestPrice) -
          zoomFactor;
        const bottomMargin =
          (lastPrice - lowestPrice) / (highestPrice - lowestPrice) - zoomFactor;

        candleSeries.applyOptions({
          priceScale: {
            autoScale: false,
            invertScale: false,
            alignLabels: true,
            borderVisible: false,
            borderColor: "#555ffd",
            scaleMargins: {
              top: topMargin > 0 ? topMargin : 0,
              bottom: bottomMargin > 0 ? bottomMargin : 0,
            },
          },
        });
      };
      zoomOnLastPrice();

      // Call the function to adjust scale margins
      function handleResize() {
        chart.applyOptions({ width: chartContainerRef.current.clientWidth });
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        chart.remove();
      };
    }
  }, [candleData]);

  return (
    <div className='relative w-full h-full'>
      <div className='absolute top-0 left-0 p-2 z-10'>
        {/* Replace this div with your symbol or logo */}
        <span className='text-white font-semibold'>
          Ticker:{" "}
          {isByUser
            ? transformFirstUpperCase(profile.name)
            : transformFirstUpperCase(profile.handle)}
        </span>
      </div>
      <div ref={chartContainerRef} className='w-full h-full' />
    </div>
  );
};

export default TradingViewChart;
