// App.js
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { usePrivy, useWallets } from "@privy-io/react-auth";

import "./theme.css"; // Adjust the path based on your project structure
import BuySellPage from "./components/BuySellPage";
import ProfilesList from "./components/ProfilesList";
import Tabs from "./components/Tabs";
import Feed from "./components/Feed";
import ClaimPage from "./components/ClaimPage";
import { getProfiles } from "./backendApi/api";
import CreatePage from "./components/CreatePage";
import { getEthPrice, ethDefaultPrice } from "./util/util";

const profilesData = [
  {
    id: 1,
    image:
      "https://d.newsweek.com/en/full/1962972/spacex-owner-tesla-ceo-elon-musk.jpg",
    title: "Elon",
    description: "Chief Troll Officer",
  },
  {
    id: 2,
    image:
      "https://media-cldnry.s-nbcnews.com/image/upload/t_fit-760w,f_auto,q_auto:best/rockcms/2024-01/240116-donald-trump-ew-248p-9f9a97.jpg",
    title: "Trump",
    description: "2016 President.",
  },
  {
    id: 3,
    image:
      "https://s.abcnews.com/images/International/biden-michigan-ap-rc-200909_hpMain.jpg",
    title: "Biden",
    description: "Description for Profile 1",
  },
  // ... add more profiles
];

const postsData = [
  {
    author: "User1",
    time: "2h ago",
    content:
      "This is a sample post content #1. This is a sample post content #1.  This is a sample post content #1.  This is a sample post content #1.  This is a sample post content #1.  This is a sample post content #1.  This is a sample post content #1.  This is a sample post content #1.  This is a sample post content #1.",
  },
  {
    author: "User2",
    time: "2h ago",
    content: "This is a sample post content #1.",
  },
  // ... more posts
];

const holdingsData = [
  {
    title: "Holding Title",
    holdings: 123, // Replace with the actual number of holdings
    lpFees: 456, // Replace with the actual LP fees
    newsFees: 789, // Replace with the actual news fees
  },
  {
    title: "Holding Title",
    holdings: 123, // Replace with the actual number of holdings
    lpFees: 456, // Replace with the actual LP fees
    newsFees: 789, // Replace with the actual news fees
  },
];

const App = () => {
  const { connectWallet } = usePrivy();
  const { wallets } = useWallets();

  const [activeTab, setActiveTab] = useState("Home");

  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    getProfiles()
      .then((profiles) => {
        setProfiles(profiles);
      })
      .catch((err) => {
        console.log("error fetching profiles: ", err);
      });
  }, []);

  // const [sender, setSender] = useState(null);
  // const connectWallet = async () => {
  //   // MetaMask connection logic
  //   if (window.ethereum) {
  //     try {
  //       const accounts = await window.ethereum.request({
  //         method: "eth_requestAccounts",
  //       });
  //       if (accounts.length > 0) {
  //         // setIsConnected(true); // Set connected status
  //         setSender(accounts[0]);
  //       }
  //     } catch (error) {
  //       // Handle any errors
  //       console.error(error);
  //     }
  //   } else {
  //     alert("Please install MetaMask!");
  //   }
  // };

  const [ethPrice, setEthPrice] = useState(ethDefaultPrice);
  useEffect(() => {
    getEthPrice()
      .then((res) => {
        if (res) {
          setEthPrice(res);
        }
      })
      .catch((err) => {
        setEthPrice(ethDefaultPrice);
      });
  }, []);

  return (
    <Router>
      <div>
        <div className='absolute top-0 right-0 m-4'>
          <button
            onClick={connectWallet}
            className={
              wallets[0]
                ? "bg-yellow-300 text-black font-bold py-2 px-4 rounded"
                : "bg-yellow-300 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded"
            }
            disabled={wallets[0] ? true : false}>
            {wallets[0] ? "Connected" : "Connect Wallet"}
          </button>
        </div>
        <div className='pt-8'>
          <Tabs activeTab={activeTab} setActiveTab={setActiveTab} />
          <Routes>
            <Route
              path='home'
              element={<ProfilesList profiles={profiles} ethPrice={ethPrice} />}
            />
            <Route path='feed' element={<Feed />} />
            <Route path='claim' element={<ClaimPage />} />
            <Route
              path='create'
              element={<CreatePage profiles={profiles} ethPrice={ethPrice} />}
            />
            <Route path='/profile/:handle' element={<BuySellPage />} />
            <Route path='*' element={<Navigate replace to='/home' />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;
