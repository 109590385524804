import {
  FeedUrl,
  ProfilesUrl,
  ProfileUrl,
  MyMemesUrl,
  TransactionsByMemeUrl,
  ProfilesByUserUrl,
} from "./apiUrls";
import axios from "axios";

const getFeed = async () => {
  const url = FeedUrl;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving feed", err);
      return err;
    });
};

const getProfiles = async () => {
  const url = ProfilesUrl;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving candidates", err);
      return err;
    });
};

const getProfile = async (handle) => {
  const url = ProfileUrl + "/" + handle;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving candidates", err);
      return err;
    });
};

const addProfile = async (profile) => {
  const url = ProfileUrl;
  return axios
    .post(url, profile)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving candidates", err);
      return err;
    });
};

const getUserCandidates = async () => {
  const url = ProfilesByUserUrl;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving user created candidates", err);
      return err;
    });
};

const getUserHoldings = async (address) => {
  const url = MyMemesUrl + "/" + address;
  // console.log("url: ", url);
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving user holdings", err);
      return err;
    });
};

const getTransactionsByMeme = async (memeId) => {
  const url = TransactionsByMemeUrl + "/" + memeId;
  return axios
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log("Error retrieving user holdings", err);
      return err;
    });
};

export {
  getFeed,
  getProfiles,
  getProfile,
  getUserHoldings,
  getTransactionsByMeme,
  addProfile,
};
