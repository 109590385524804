import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
import { timeSince } from "../util/util";
// import { authorIdToMetadata } from "../util/authorMetadata";
import BurnedMemesModal from "./BurnedMemeComponent";

const Post = ({ index, post }) => {
  // const metadata = authorIdToMetadata[post.authorId];

  // const [tweetUrl, setTweetUrl] = useState(null);
  // useEffect(() => {
  //   const handle = metadata && metadata.handle;
  //   const tweetId = post && post.tweetId;
  //   const baseUrl = "https://twitter.com";
  //   const url = `${baseUrl}/${handle}/status/${tweetId}`;
  //   setTweetUrl(url);
  // }, [metadata, post]);

  const navigate = useNavigate();
  const onClickViewBurnedMemes = () => {
    navigate(`/profile/${post.handle}`);
  };

  const [isBurnedMemesModalOpen, setBurnedMemesModalOpen] = useState(false);

  const toggleBurnedMemesModal = () => {
    setBurnedMemesModalOpen(!isBurnedMemesModalOpen);
  };

  const [tweetUrl, setTweetUrl] = useState(null);
  useEffect(() => {
    setTweetUrl(`https://twitter.com/${post.handle}/status/${post.tweetId}`);
  }, [post]);

  return (
    <>
      <div
        key={index}
        className='post bg-gray-800 p-4 rounded-lg mb-4 shadow hover:shadow-md transition duration-300 ease-in-out'>
        <div className='post-header flex items-center mb-2'>
          {/* Placeholder for profile picture */}
          {post ? (
            <img
              src={post.imgUrl}
              alt='Profile'
              className='profile-pic w-10 h-10 rounded-full mr-3'
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "default-profile-pic-url";
              }}
            />
          ) : (
            <div className='profile-pic w-10 h-10 rounded-full bg-gray-700 mr-3'></div>
          )}
          <div>
            <div className='flex items-center'>
              <h3 className='font-semibold mr-2'>
                {post.name ? post.name : "name"}
              </h3>
              <p className='text-xs text-gray-400'>
                {timeSince(post.timestamp._seconds)}
              </p>
            </div>
            <p className='text-xs text-gray-400'>
              @{post.handle ? post.handle : "handle"}
            </p>
          </div>
          {post.burnedHash && (
            <button
              className='claim-button bg-red-500 hover:bg-red-600 text-white px-2 py-1 rounded-md ml-auto z-10'
              onClick={toggleBurnedMemesModal}>
              Burned Memes
            </button>
          )}
        </div>
        <a
          href={tweetUrl}
          target='_blank'
          rel='noopener noreferrer'
          className='no-underline text-gray-300'>
          {post.text}
        </a>
      </div>
      <BurnedMemesModal
        isOpen={isBurnedMemesModalOpen}
        onClose={toggleBurnedMemesModal}
        onClickViewBurnedMemes={onClickViewBurnedMemes}
        post={post}
      />
      {/* {isModalVisible && (
        <div className='modal'>
          <div className='modal-content'>
            <span className='close-button' onClick={toggleModal}>
              &times;
            </span>
            <h2>Title of Popup</h2>
            <p>Body paragraph goes here...</p>
            <a
              href='https://etherscan.io/tx/YOUR_TX_HASH'
              target='_blank'
              rel='noopener noreferrer'>
              tx: hash
            </a>
          </div>
        </div>
      )} */}
    </>
  );
};

export default Post;
