import React, { useEffect, useState } from "react";
import { getUserHoldings } from "../backendApi/api";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  getMemeLpFeesForUser,
  getClaimMemeFeeTx,
} from "../contracts/memeFunctions";
import { weiToEth } from "../util/util";
import { sendTx } from "../util/wallet";
import FaqModal from "./FAQComponent";
import { useWallets } from "@privy-io/react-auth";

const HoldingComponent = ({ index, holding, wallet }) => {
  const navigate = useNavigate();
  const onClickHolding = (holding) => {
    navigate(`/profile/${holding.handle}`);
  };

  const [lpFees, setLpFees] = useState(0);
  useEffect(() => {
    getMemeLpFeesForUser({ memeId: holding.memeId, sender: wallet?.address })
      .then((res) => {
        setLpFees(res);
      })
      .catch((err) => {
        console.log("err fetching lpfees", err);
      });
  }, [holding, wallet]);

  const onClickClaimLpFees = async () => {
    // const sender = await getSender();
    // const sender = "0xa6C19aDb4Cba619a637954c739eaF708e5404055";
    const addMemeTx = await getClaimMemeFeeTx({
      memeId: holding.memeId,
      sender: wallet?.address,
    });
    await sendTx(addMemeTx, wallet);
  };

  return (
    <div
      key={index}
      className='holding bg-gray-800 p-4 rounded-lg mb-4 shadow hover:shadow-lg transition-shadow duration-300 cursor-pointer'
      onClick={() => onClickHolding(holding)}>
      <div className='holding-info flex items-center mb-2'>
        {/* Placeholder for profile picture */}
        <div className='profile-pic w-10 h-10 rounded-full bg-gray-700 mr-3'>
          <img
            src={holding.imgUrl}
            alt='candidate'
            className='w-full h-full object-cover rounded-full'
          />
        </div>
        <div className='holding-details'>
          <h3 className='font-semibold'>{holding.name}</h3>
          <p className='text-xs text-gray-400'>Holdings: {holding.meming}</p>
        </div>
        <div className='lp-fees text-xs text-gray-400 ml-auto'>
          LP Fees: {weiToEth(Number(lpFees)).toFixed(8)}
        </div>
        <button
          className='claim-button bg-yellow-300 text-black px-2 py-1 rounded-md ml-2 hover:bg-yellow-500 transition-colors duration-300'
          onClick={(e) => {
            e.stopPropagation(); // Prevents click event from reaching the parent div
            onClickClaimLpFees(holding);
          }}>
          Claim
        </button>
      </div>
    </div>
  );
};

const ClaimPage = () => {
  const { wallets } = useWallets();

  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const handleOpenFaq = () => {
    setIsFaqOpen(true);
  };

  const handleCloseFaq = () => {
    setIsFaqOpen(false);
  };

  // useEffect(() => {
  //   if (!sender) {
  //     connectWallet();
  //   }
  // }, [sender, connectWallet]);

  const [holdings, setHoldings] = useState([]);
  useEffect(() => {
    if (wallets[0]) {
      getUserHoldings(wallets[0].address)
        .then((res) => {
          console.log("holdings: ", res);
          setHoldings(res);
        })
        .catch((err) => {
          console.log("err fetching holdings", err);
        });
    }
  }, [wallets]);

  return (
    <div className='holdings-container mx-auto my-4 max-w-md md:max-w-lg lg:max-w-2xl'>
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-2xl font-bold ml-18'>Holdings</h2>
        <h2
          className='text-lg underline cursor-pointer hover:text-yellow-500'
          onClick={handleOpenFaq}>
          How it Works
        </h2>
      </div>
      {!wallets[0] ? (
        <p className='text mt-4 mb-4 ml-18 text-gray-400'>
          Connect your wallet to see your holdings.
        </p>
      ) : holdings.length > 0 ? (
        holdings.map((holding, index) => (
          <HoldingComponent
            key={index}
            index={index}
            holding={holding}
            wallet={wallets[0]}
          />
        ))
      ) : (
        <p className='text mt-4 mb-4 ml-18 text-gray-400'>
          You haven't aped any memes yet. You will see your holdings here. You
          can earn trading fees simply by holding these memes. For memes on the
          home page, protocol uses 5% of bounty to buy and burn these memes
          whenever the person/company tweets.
        </p>
      )}
      <FaqModal isOpen={isFaqOpen} onClose={handleCloseFaq} />
    </div>
  );
};

export default ClaimPage;
